import React from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Form from "../components/Form"
import Treatments from "../components/Treatments"

import * as S from "../components/TreatmentsPost/styled"

const TreatmentsPost = ({ data }) => {
    const treatmentsPost = data.markdownRemark
    
    return (
        <Layout title="Tratamentos">
            <SEO title={ treatmentsPost.frontmatter.title } description={ treatmentsPost.frontmatter.description } image={ treatmentsPost.frontmatter.img } />
            <S.Container>
                <S.InnerContainer>
                    <S.PostHeader>  
                        <S.PostTitle>{ treatmentsPost.frontmatter.title }</S.PostTitle>
                        <S.PostHighlightTitle>{ treatmentsPost.frontmatter.highlightTitle }</S.PostHighlightTitle>
                    </S.PostHeader>
                    <S.MainContent>
                        <div dangerouslySetInnerHTML={{ __html: treatmentsPost.html }}></div>
                    </S.MainContent>
                </S.InnerContainer>
                <S.ImageContainer>
                    <S.Img>
                        <Img fluid={treatmentsPost.frontmatter.img.childImageSharp.fluid}  />
                    </S.Img>
                </S.ImageContainer>
            </S.Container>
            <Treatments posts={data.allMarkdownRemark.edges}/>
            <Form /> 
        </Layout>
    )
}

export const query = graphql`
    query Treatment($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            frontmatter {
                title
                highlightTitle
                img {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
            }
            html
        }
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(tratamentos)/"  }}) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
              highlightTitle
              title
              img {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
            }
          }
        }
      }
    }
`

export default TreatmentsPost
import styled from "styled-components"
import media from 'styled-media-query'

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    grid-gap: 80px;
    max-width: 90%;
    padding: 10px;
    margin: 0 auto;

    ${media.lessThan("large")`
        grid-template-columns: 1fr;        
        grid-gap: 20px;
    `}
    
    ${media.lessThan("medium")`
        grid-template-columns: 1fr;        
        grid-gap: 0;
    `}
`

export const InnerContainer = styled.div`
    width: 100%;

    h2 {
      color: #399F9B;
      font-size: 3rem;
      font-weight: 700;
      padding: 50px 1.4rem 20px 1.4rem;

      ${media.lessThan("large")`
        font-size: 1rem;
        line-height: 1.1;
        padding: 0 1rem;
      `}
    }
`

export const ImageContainer = styled.div`
`

export const Img = styled.div`
    display: block;
    width: 40%;
    height: 400px;
    position: absolute;
    margin-top: 70px;
    right: 0;
    background-color: #29559C;
    border-radius: 100px 0 0 100px;
    background-image: url(${ props => props.img }); 
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    ${media.lessThan("large")`
            position: relative;
            margin: 0 auto;
            width: 100%;
            border-radius: 100px;
            
    `}

    ${media.lessThan("medium")`
            height: 200px;
            border-radius: 30px;
    `}    
`

export const PostHeader = styled.header`
  padding: 5rem 5rem 0;
  font-family: 'Titillium Web', sans-serif;
  text-transform: uppercase;
  height: 100%;

  ${media.lessThan("large")`
    padding: 3rem 0 0;
    max-width: 100%;
  `}
`

export const PostTitle = styled.h1`
  color: #29559C;
  font-size: 2.2rem;
  font-weight: 700;
  padding: 0 1.4rem;
  margin: 0 auto;

  ${media.lessThan("large")`
    font-size: 1rem;
    line-height: 1.1;
    padding: 0 1rem;
  `}
`

export const PostHighlightTitle = styled.h1`
  color: #29559C;
  font-size: 4rem;
  font-weight: 700;
  padding: 0 1.4rem;

  ${media.lessThan("large")`
    font-size: 2rem;
    line-height: 1.1;
    padding: 0 1rem;
  `}
`

export const MainContent = styled.section`
  margin: auto;
  max-width: 70rem;
  padding: 2rem 5rem;

  ${media.lessThan("large")`
    padding: 2rem 0;
    max-width: 100%;
  `}

  p,
  h1,
  h2,
  h3,
  h4,
  ul,
  ol,
  .tags,
  iframe,
  .button-post {
    color: #969696;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.7;
    padding: 0 1.4rem;
    font-family: 'Montserrat', sans-serif;

    ${media.lessThan("large")`
      padding: 0 1rem;
      word-break: break-word;
    `}
  }
  p {
    margin: 0 auto 1.6rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 2.4rem auto 1rem;
  }
  ul,
  ol {
    list-style: disc;
    padding-left: 2.5rem;
    margin: 0 auto 1.6rem;
  }
  li {
    padding: 0.625rem 0;
    & > ul {
      margin-bottom: 0;
    }
  }
  p,
  li {
    code {
      word-wrap: break-word;
    }
  }
  img {
    display: block;
    max-width: 100%;
    margin: 1.875rem auto;
  }
  iframe {
    padding: 0 1.6rem 1.6rem;
    width: 100%;

    ${media.lessThan("large")`
      padding: 0 1rem;
    `}
  }
  blockquote {
    color: #fff;
    border-left: 0.3rem solid #1fa1f2;
    padding: 0 1.875rem;
    margin: 3.125rem auto;
  }
  hr {
    border: 1px solid #38444d;
    margin: 3rem auto;
  }
  #twitter-widget-0,
  .instagram-media,
  .twitter-tweet {
    margin: 20px auto !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 800;
    letter-spacing: 0.069rem;
    line-height: 1.4;
  }
  h1 {
    font-size: 2.8rem;

    ${media.lessThan("large")`
      font-size: 1.875rem;
    `}
  }
  h2 {
    font-size: 2.1rem;
    ${media.lessThan("large")`
      font-size: 1.375rem;
    `}
  }
  h3 {
    font-size: 1.6rem;
    ${media.lessThan("large")`
      font-size: 1.125rem;
    `}
  }
  h5 {
    font-size: 1.2rem;
  }
  strong {
    font-weight: 700;
  }
  .gatsby-resp-image-background-image {
    z-index: 2;
    opacity: 1 !important;
  }
  .gatsby-resp-image-image {
    box-shadow: none !important;
    transition: opacity 0.2s;
    &.lazyload {
      opacity: 0;
    }
    &.lazyloaded {
      opacity: 1;
      z-index: 3;
    }
  }
  .gatsby-highlight {
    padding: 0 1.6rem 1.6rem;

    ${media.lessThan("large")`
      padding: 0;
    `}
  }
  .instagram-media {
    margin: 1rem auto !important;
  }
  a {
    border-bottom: 1px dashed #1fa1f2;
    color: #1fa1f2;
    text-decoration: none;
    transition: opacity 0.5s;
    svg {
      color: #fff;
    }
    &:hover {
      opacity: 0.8;
    }
  }
`
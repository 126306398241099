import styled from 'styled-components'
import media from 'styled-media-query'


export const CardsContainer = styled.div`

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 30px 100px;

    position: relative;
    z-index: 200;

    
    ${media.lessThan("small")`
        padding: 30px 20px;
    `}

`

export const Card = styled.div`
    flex: 0 1 270px;
    margin: 20px;

    max-width: 270px;
    border-radius: 10px;
    background-color: #fff;

    display: flex!important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    position: relative;
    z-index: 200;

    transition: all 0.75s ease;

    h4 {
        padding: 20px;

        font-family: 'Titillium Web', sans-serif;
        font-size: 1.3rem;
        color: #214191;
        font-weight: 700;
        text-transform: uppercase;

        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 95%;

        b {
            font-size: 1.3rem;
            color: #214191;
            font-weight: 700;
        }
    } 

    hr {
        border: none;
        height: 4px;
        background-color: #0C8E86;
        width: 40%;

        margin-top: 0;
        margin-left: 0;
    }

    span {
        font-family: 'Montserrat', sans-serif;
        text-align: left;
        color: #fff;
        font-size: 0.6rem;
        font-weight: 700;
        padding: 10px 20px;
        background: transparent linear-gradient(180deg, #37A19B 0%, #416DB2 100%) 0% 0% no-repeat padding-box;
        border-radius: 24px;
        margin: 25px 0 5px 5px;

        cursor: pointer;

        a {
            color: #fff;
        }
    }

    &:hover {
        transform: scale(1.02);
    }

    ${media.lessThan("small")`
        box-shadow: 0px 0px 12px 8px rgba(0,0,0,0.05);
    `}
`

export const Img = styled.div`
    border-radius: 10px 10px 0 0;
    display: block;
    width: 100%;
    height: 150px;
    background-color: #375b93;
    background-image: url(${ props => props.img }); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;    
`
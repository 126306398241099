import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import * as S from "./styled"

const Treatments = ({ posts }) => {

    return (
        <>
            <S.CardsContainer>
                {
                    posts.map(({node: { id, fields: { slug }, frontmatter: { title, highlightTitle, img } }}) => {
                        return (
                            <S.Card key={id}>
                                <S.Img>
                                   <Img fluid={img.childImageSharp.fluid}  />
                                </S.Img>
                                <h4>{title} <br /><b>{highlightTitle}</b></h4>
                                <hr />

                                <span><Link to={`/tratamentos${slug}`}>Ler mais</Link></span>

                            </S.Card>
                        )
                    })
                }
            </S.CardsContainer>
        </>
    )

}
Treatments.propTypes = {
    posts: PropTypes.array.isRequired,
}

export default Treatments